<template>
  <div class="homepage-5 the-search hd-white">
    <div id="wrapper">
      <section
        style="
          background-color: #272c32;
          background-position: center center;
          background-repeat: no-repeat;
          background-attachment: fixed;
          background-size: cover;
        "
        id="hero-area"
        class="parallax-searchs home17 overlay res-bimg"
        data-stellar-background-ratio="0.5"
        v-lazyload
        :data-url="require('../assets/images/bighome.png')"
      >
        <div class="hero-main">
          <div class="container">
            <div class="banner-inner-wrap res-p res-display-unset">
              <div
                class="row justify-content-md-center w1001 res-margin-seting"
              >
                <div
                  style="margin-bottom: -30px !important"
                  class="col-12 mb-3 mt-3"
                >
                  <div class="banner-inner justify-content-center">
                    <div class="pl-3 pr-3">
                      <h2 class="title text-center mb-0 text-white">
                        The UK's DIY marketplace for <br />
                        selling your property
                      </h2>
                    </div>
                  </div>
                </div>
                <div data-v-2a183b29="" class="col-12">
                  <div
                    data-v-2a183b29=""
                    class="banner-inner justify-content-center"
                  >
                    <div
                      style="margin-bottom: 0px"
                      data-v-2a183b29=""
                      class="pl-3 pr-3 res-plt"
                    >
                      <h3 style="color: white" class="mt-5 text-center">
                        <!-- Join 1000’s of smart sellers -->
                      </h3>

                      <div class="text-center">
                        <a href="#how-it-work">
                        <button
                          class="res-button-how res-butt mx-2 my-2"
                          @click="takeToHowItWorks"
                          style="
                            background-color: #548f4d;
                            color: white;
                            padding: 20px 89px;

                            border-radius: 15px;

                            border-color: rgb(84, 143, 77) !important;
                            border: 0px;
                            font-family: 'Montserrat', sans-serif;
                          "
                        >
                          How it Works
                        </button>
                        </a>
                        <button
                          class="res-button-prop"
                          @click="navigate('/add-property')"
                          style="
                            background-color: #548f4d;
                            color: white;
                            padding-top: 20px;
                            padding-left: 60px;
                            padding-bottom: 20px;
                            padding-right: 60px;
                            border-radius: 15px;

                            border-color: rgb(84, 143, 77) !important;
                            border: 0px;
                            font-family: 'Montserrat', sans-serif;
                          "
                        >
                          List your own property
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-8 res-plr-0 resp-margin-topp">
                  <div class="banner-search-wrap">
                    <div class="tab-content">
                      <div
                        class="tab-pane fade show active res-marginr-20"
                        id="tabs_1"
                      >
                        <div>
                          <div
                            class="rld-main-search"
                            :style="`
                            ${
                              searchOpen
                                ? `border-bottom-left-radius: 0px;
                              border-bottom-right-radius: 0px;`
                                : ''
                            }
                              `"
                          >
                            <div
                              class="row"
                              style="margin-left: -21px; margin-right: -21px"
                            >
                              <div
                                style="padding-left: 20px"
                                class="rld-single-input res-pl-0 res-mrl-15"
                              >
                                <input
                                  style="margin-left: 10px !important"
                                  class="res_remove_ml w-124 res-postal-code"
                                  type="text"
                                  placeholder="Postcode/Town"
                                  @change="
                                    addToQuery('postCode', $event.target.value)
                                  "
                                />
                              </div>
                              <div class="rld-single-select res-mrl-15 w-124">
                                <multiselect
                                  :searchable="false"
                                  select-label=""
                                  deselectLabel=""
                                  selectedLabel=""
                                  v-if="findIfActive('location') == 1"
                                  @input="
                                    addToQuery(
                                      'radius',
                                      _.get($event, 'value', '')
                                    )
                                  "
                                  :placeholder="'Search Radius'"
                                  v-model="radiusOptions"
                                  :options="[
                                    { label: 'Within 1/4 mile', value: '1/4' },
                                    { label: ' Within 1/2 mile', value: '1/2' },
                                    { label: 'Within 3 miles', value: '3' },
                                    {
                                      label: 'Within 5 miles',
                                      value: '5',
                                    },
                                    { label: 'Within 10 miles', value: '10' },
                                    { label: 'Within 15 miles', value: '15' },
                                    { label: 'Within 20 miles', value: '20' },
                                    { label: 'Within 30 miles', value: '30' },
                                    { label: 'Within 40 miles', value: '40' },
                                  ]"
                                  :clear-on-select="false"
                                  :preserve-search="true"
                                  label="label"
                                  track-by="value"
                                >
                                  <template
                                    slot="selection"
                                    slot-scope="{ values, isOpen }"
                                    ><span
                                      class="multiselect__single"
                                      v-if="values.length &amp;&amp; !isOpen"
                                      >{{ values.length }} option{{
                                        values.length > 1 ? "s" : ""
                                      }}
                                      selected</span
                                    ></template
                                  >
                                  <template #tag
                                    ><p class="d-none"></p>
                                  </template>
                                </multiselect>
                              </div>

                              <div
                                style="padding-right: 25px"
                                class="col res-search-button-new pl-0"
                              >
                                <button
                                  style="
                                    border-color: #548f4d !important;
                                    color: White !important;
                                  "
                                  @click="search"
                                  class="btn btn-yellow"
                                >
                                  Search Now
                                </button>
                              </div>
                              <div
                                :style="`
                                  width: 100%;
                                  padding:0;
                                  box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 9px;
                                  
                                  border-top-left-radius: 0px;
                                  border-top-right-radius: 0px;
                                  border-bottom-left-radius: 5px;
                                  border-bottom-right-radius: 5px;
                                  `"
                                class="
                                  explore__form-checkbox-list
                                  full-filter
                                  d-lg-block d-none
                                "
                              >
                                <div
                                  class="inside-advance"
                                  style="padding: 30px 30px 20px 30px"
                                >
                                  <div
                                    class="
                                      col-lg-12 col-md-12 col-sm-12
                                      py-1
                                      pr-30
                                      mr-5
                                      sld
                                    "
                                  >
                                    <div class="main-search-field-2">
                                      <div
                                        v-if="findIfActive('priceRange') == 1"
                                        class="range-slider"
                                      >
                                        <label
                                          >Price Range: £{{
                                            formatter(value[0])
                                          }}
                                          to £{{ formatter(value[1]) }}</label
                                        >
                                        <vue-range-slider
                                          :tooltip="false"
                                          :data="rangeValues"
                                          @slide-end="
                                            addToQuery('price', $event)
                                          "
                                          :tooltip-dir="'bottom'"
                                          :tooltip-style="{
                                            'background-color': '#3e4452',
                                            'border-color': '#3e4452',
                                          }"
                                          :process-style="{
                                            'background-color': '#3e4452',
                                          }"
                                          ref="slider"
                                          v-model="value"
                                        >
                                          <template
                                            slot="tooltip"
                                            slot-scope="{ value }"
                                          >
                                            <div class="diy-tooltip">
                                              {{ formatter(value) }}
                                            </div>
                                          </template>
                                        </vue-range-slider>
                                        <div class="clearfix"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12 p-0 mt-4 mb-4">
                                  <div
                                    class="
                                      display
                                      checkboxes
                                      one-in-row
                                      margin-bottom-10
                                      ch-1
                                      mr-2
                                    "
                                  >
                                    <div class="col pr-0 res-pl-0">
                                      <div
                                        class="form-group bath remove-margin"
                                      >
                                        <span
                                          class="res-bathroom-icon"
                                          style="
                                            position: absolute;
                                            left: 16%;
                                            z-index: 5;
                                            top: 14px;
                                          "
                                          ><img
                                            class="bedroomAdvance"
                                            :src="
                                              require('../assets/images/icons/Bathroom-Icon.webp')
                                            "
                                        /></span>
                                        <multiselect
                                          :searchable="false"
                                          v-if="
                                            findIfActive('noOfBathrooms') == 1
                                          "
                                          @input="
                                            addToQuery(
                                              'bathrooms',
                                              _.get($event, 'value', '')
                                            )
                                          "
                                          :placeholder="'Bathrooms'"
                                          v-model="noOfBathrooms"
                                          :options="[
                                            { label: '1', value: '1' },
                                            { label: ' 2', value: '2' },
                                            { label: '3', value: '3' },
                                            {
                                              label: '4',
                                              value: '4',
                                            },
                                            { label: '5', value: '5' },
                                            { label: '6', value: '6' },
                                          ]"
                                          :clear-on-select="false"
                                          :preserve-search="true"
                                          label="label"
                                          track-by="value"
                                        >
                                          <template
                                            slot="selection"
                                            slot-scope="{ values, isOpen }"
                                            ><span
                                              class="multiselect__single"
                                              v-if="values.length &amp;&amp; !isOpen"
                                              >{{ values.length }} option{{
                                                values.length > 1 ? "s" : ""
                                              }}
                                              selected</span
                                            ></template
                                          >
                                          <template #tag
                                            ><p class="d-none"></p>
                                          </template>
                                        </multiselect>
                                      </div>
                                    </div>
                                    <div class="col pl-0 pr-0">
                                      <div
                                        class="form-group bath remove-margin"
                                      >
                                        <span
                                          style="
                                            position: absolute;
                                            left: 10%;
                                            z-index: 5;
                                            top: 14px;
                                            margin-right: 40px;
                                          "
                                          ><img
                                            class="bedroomAdvance"
                                            :src="
                                              require('../assets/images/icons/Garage-Icon.webp')
                                            "
                                        /></span>
                                        <multiselect
                                          :searchable="false"
                                          select-label=""
                                          deselectLabel=""
                                          selectedLabel=""
                                          v-if="findIfActive('garage') == 1"
                                          @input="addToQuery('garage', $event)"
                                          :placeholder="'Garage'"
                                          :multiple="true"
                                          v-model="garageOptions"
                                          :options="[
                                            {
                                              label: 'Single',
                                              value: 'single',
                                            },
                                            {
                                              label: 'Double',
                                              value: 'double',
                                            },
                                            { label: 'Other', value: 'other' },
                                            { label: 'None', value: 'none' },
                                          ]"
                                          :clear-on-select="false"
                                          :preserve-search="true"
                                          label="label"
                                          track-by="value"
                                        >
                                          <template
                                            slot="selection"
                                            slot-scope="{ values, isOpen }"
                                            ><span
                                              class="multiselect__single"
                                              v-if="values.length &amp;&amp; !isOpen"
                                              >{{ values.length }} option{{
                                                values.length > 1 ? "s" : ""
                                              }}
                                              selected</span
                                            ></template
                                          >
                                          <template #tag
                                            ><p class="d-none"></p>
                                          </template>
                                        </multiselect>
                                      </div>
                                    </div>
                                    <div class="col pl-0 pr-0">
                                      <div
                                        class="form-group bath remove-margin"
                                      >
                                        <span
                                          class="res-parking-icon"
                                          style="
                                            position: absolute;
                                            left: 11%;
                                            z-index: 5;
                                            top: 15px;
                                          "
                                          ><img
                                            class="bedroomAdvance"
                                            :src="
                                              require('../assets/images/icons/Parking-Icon.webp')
                                            "
                                        /></span>
                                        <multiselect
                                          :searchable="false"
                                          select-label=""
                                          deselectLabel=""
                                          selectedLabel=""
                                          v-if="findIfActive('parking') == 1"
                                          @input="addToQuery('parking', $event)"
                                          :placeholder="'Parking'"
                                          :multiple="true"
                                          v-model="parkingOptions"
                                          :options="[
                                            {
                                              label: 'Off Street',
                                              value: 'offStreet',
                                            },
                                            {
                                              label: 'On Street',
                                              value: 'onStreet',
                                            },
                                            {
                                              label: 'Allocated',
                                              value: 'allocated',
                                            },
                                            {
                                              label: 'Permitted',
                                              value: 'permitted',
                                            },
                                          ]"
                                          :clear-on-select="false"
                                          :preserve-search="true"
                                          label="label"
                                          track-by="value"
                                        >
                                          <template
                                            slot="selection"
                                            slot-scope="{ values, isOpen }"
                                            ><span
                                              class="multiselect__single"
                                              v-if="values.length &amp;&amp; !isOpen"
                                              >{{ values.length }} option{{
                                                values.length > 1 ? "s" : ""
                                              }}
                                              selected</span
                                            ></template
                                          >
                                          <template #tag
                                            ><p class="d-none"></p>
                                          </template>
                                        </multiselect>
                                      </div>
                                    </div>
                                    <div class="col pr-0 res-pl-0">
                                      <div
                                        class="form-group bath remove-margin"
                                      >
                                        <span
                                          class="res-garden-icon"
                                          style="
                                            position: absolute;
                                            left: 17%;
                                            z-index: 5;
                                            top: 14px;
                                          "
                                          ><img
                                            class="bedroomAdvance"
                                            :src="
                                              require('../assets/images/icons/Garden-Icon.webp')
                                            "
                                        /></span>
                                        <multiselect
                                          :searchable="false"
                                          select-label=""
                                          deselectLabel=""
                                          selectedLabel=""
                                          v-if="
                                            findIfActive('noOfBathrooms') == 1
                                          "
                                          @input="
                                            addToQuery(
                                              'garden',
                                              _.get($event, 'value', '')
                                            )
                                          "
                                          :placeholder="'Garden'"
                                          v-model="gardenOptions"
                                          :options="[
                                            { label: 'Yes', value: 'yes' },
                                            { label: ' No', value: 'no' },
                                          ]"
                                          :clear-on-select="false"
                                          :preserve-search="true"
                                          label="label"
                                          track-by="value"
                                        >
                                          <template
                                            slot="selection"
                                            slot-scope="{ values, isOpen }"
                                            ><span
                                              class="multiselect__single"
                                              v-if="values.length &amp;&amp; !isOpen"
                                              >{{ values.length }} option{{
                                                values.length > 1 ? "s" : ""
                                              }}
                                              selected</span
                                            ></template
                                          >
                                          <template #tag
                                            ><p class="d-none"></p>
                                          </template>
                                        </multiselect>
                                      </div>
                                    </div>
                                    <div class="col pl-0 pr-0">
                                      <div
                                        class="form-group bath remove-margin"
                                      >
                                        <span
                                          class="res-swmin-icon"
                                          style="
                                            position: absolute;
                                            left: 11.5%;
                                            z-index: 5;
                                            top: 16px;
                                          "
                                          ><img
                                            class="bedroomAdvance"
                                            :src="
                                              require('../assets/images/icons/Swimming-Pool-Icon.webp')
                                            "
                                        /></span>
                                        <multiselect
                                          :searchable="false"
                                          select-label=""
                                          deselectLabel=""
                                          selectedLabel=""
                                          v-if="
                                            findIfActive('swimmingPool') == 1
                                          "
                                          @input="
                                            addToQuery('swimmingPool', $event)
                                          "
                                          :placeholder="'Swimming Pool'"
                                          :multiple="true"
                                          v-model="swimmingPoolOptions"
                                          :options="[
                                            {
                                              label: 'Indoor',
                                              value: 'indoor',
                                            },
                                            {
                                              label: 'Outdoor',
                                              value: 'outdoor',
                                            },
                                            { label: 'None', value: 'none' },
                                          ]"
                                          :clear-on-select="false"
                                          :preserve-search="true"
                                          label="label"
                                          track-by="value"
                                        >
                                          <template
                                            slot="selection"
                                            slot-scope="{ values, isOpen }"
                                            ><span
                                              class="multiselect__single"
                                              v-if="values.length &amp;&amp; !isOpen"
                                              >{{ values.length }} option{{
                                                values.length > 1 ? "s" : ""
                                              }}
                                              selected</span
                                            ></template
                                          >
                                          <template #tag
                                            ><p class="d-none"></p>
                                          </template>
                                        </multiselect>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="tabs_2">
                        <div class="rld-main-search">
                          <div class="row">
                            <div class="rld-single-input">
                              <input
                                type="text"
                                placeholder="Enter postCode..."
                              />
                            </div>
                            <div class="rld-single-select ml-22">
                              <select class="select single-select">
                                <option value="1">Property Type</option>
                                <option value="2">Family House</option>
                                <option value="3">Apartment</option>
                                <option value="3">Condo</option>
                              </select>
                            </div>
                            <div class="rld-single-select">
                              <select class="select single-select mr-0">
                                <option value="1">Location</option>
                                <option value="2">Los Angeles</option>
                                <option value="3">Chicago</option>
                                <option value="3">Philadelphia</option>
                                <option value="3">San Francisco</option>
                                <option value="3">Miami</option>
                                <option value="3">Houston</option>
                              </select>
                            </div>
                            <div class="dropdown-filter">
                              <span>Advanced Search</span>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 pl-0">
                              <a
                                @click="search"
                                class="btn btn-yellow bsd"
                                href="javascript:void(0)"
                                >Search Now</a
                              >
                            </div>
                            <div
                              class="explore__form-checkbox-list full-filter"
                            >
                              <div class="row">
                                <div class="col-lg-4 col-md-6 py-1 pr-30 pl-0">
                                  <div class="form-group categories">
                                    <div
                                      class="nice-select form-control wide"
                                      tabindex="0"
                                    >
                                      <span class="current"
                                        ><i class="fa fa-home"></i>Tenure
                                      </span>
                                      <ul class="list">
                                        <li
                                          data-value="1"
                                          class="option selected"
                                        >
                                          For Sale
                                        </li>
                                        <li data-value="2" class="option">
                                          For Rent
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-6 py-1 pr-30 pl-0">
                                  <div class="form-group beds">
                                    <div
                                      class="nice-select form-control wide"
                                      tabindex="0"
                                    >
                                      <span class="current"
                                        ><i
                                          class="fa fa-bed"
                                          aria-hidden="true"
                                        ></i>
                                        Bedrooms</span
                                      >
                                      <ul class="list">
                                        <li
                                          data-value="1"
                                          class="option selected"
                                        >
                                          1
                                        </li>
                                        <li data-value="2" class="option">2</li>
                                        <li data-value="3" class="option">3</li>
                                        <li data-value="3" class="option">4</li>
                                        <li data-value="3" class="option">5</li>
                                        <li data-value="3" class="option">6</li>
                                        <li data-value="3" class="option">7</li>
                                        <li data-value="3" class="option">8</li>
                                        <li data-value="3" class="option">9</li>
                                        <li data-value="3" class="option">
                                          10
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-6 py-1 pl-0 pr-0">
                                  <div class="form-group bath">
                                    <div
                                      class="nice-select form-control wide"
                                      tabindex="0"
                                    >
                                      <span class="current"
                                        ><i
                                          class="fa fa-bath"
                                          aria-hidden="true"
                                        ></i>
                                        Bathrooms</span
                                      >
                                      <ul class="list">
                                        <li
                                          data-value="1"
                                          class="option selected"
                                        >
                                          1
                                        </li>
                                        <li data-value="2" class="option">2</li>
                                        <li data-value="3" class="option">3</li>
                                        <li data-value="3" class="option">4</li>
                                        <li data-value="3" class="option">5</li>
                                        <li data-value="3" class="option">6</li>
                                        <li data-value="3" class="option">7</li>
                                        <li data-value="3" class="option">8</li>
                                        <li data-value="3" class="option">9</li>
                                        <li data-value="3" class="option">
                                          10
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="
                                    col-lg-5 col-md-12 col-sm-12
                                    py-1
                                    pr-30
                                    mr-5
                                    sld
                                  "
                                >
                                  <div class="main-search-field-2">
                                    <div class="range-slider">
                                      <label>Area Size</label>
                                      <div
                                        id="area-range-rent"
                                        data-min="0"
                                        data-max="1300"
                                        data-unit="sq ft"
                                      ></div>
                                      <div class="clearfix"></div>
                                    </div>
                                    <br />
                                    <div class="range-slider">
                                      <label>Price Range</label>
                                      <div
                                        id="price-range-rent"
                                        data-min="0"
                                        data-max="600000"
                                        data-unit="$"
                                      ></div>
                                      <div class="clearfix"></div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="col-lg-3 col-md-6 col-sm-12 py-1 pr-30"
                                >
                                  <div
                                    class="
                                      checkboxes
                                      one-in-row
                                      margin-bottom-10
                                      ch-1
                                    "
                                  >
                                    <input
                                      id="check-16"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-16"
                                      >Air Conditioning</label
                                    >
                                    <input
                                      id="check-17"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-17">Swimming Pool</label>
                                    <input
                                      id="check-18"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-18"
                                      >Central Heating</label
                                    >
                                    <input
                                      id="check-19"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-19">Laundry Room</label>
                                    <input
                                      id="check-20"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-20">Gym</label>
                                    <input
                                      id="check-21"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-21">Alarm</label>
                                    <input
                                      id="check-22"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-22"
                                      >Window Covering</label
                                    >
                                  </div>
                                </div>
                                <div
                                  class="col-lg-3 col-md-6 col-sm-12 py-1 pr-30"
                                >
                                  <div
                                    class="
                                      display
                                      checkboxes
                                      one-in-row
                                      margin-bottom-10
                                      ch-2
                                    "
                                  >
                                    <input
                                      id="check-23"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-23">WiFi</label>
                                    <input
                                      id="check-24"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-24">TV Cable</label>
                                    <input
                                      id="check-25"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-25">Dryer</label>
                                    <input
                                      id="check-26"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-26">Microwave</label>
                                    <input
                                      id="check-27"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-27">Washer</label>
                                    <input
                                      id="check-28"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-28">Refrigerator</label>
                                    <input
                                      id="check-29"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-29">Outdoor Shower</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        v-lazyload
        :data-url="require('../assets/images/bg/home_section.webp')"
        class="mt-0 info-help h18 pedrem"
      >
        <div class="container">
          <div class="row info-head">
            <div class="col-lg-6 col-12 d-lg-none d-block">
              <h2
                style="
                  font-weight: 700;
                  text-transform: inherit;
                  font-family: futura;
                "
                class="text-dark text-center pb-lg-3 sec-head"
              >
                So what's it all about? <br />
                Find out here
              </h2>
            </div>
            <div class="col-lg-6 col-12">
              <div class="info-text d-flex justify-content-center">
                <div class="">
                  <video
                    class="res-video w-100 h-100"
                    id="video"
                    :src="require('../assets/images/Vencasa-Short-Ad-4K.mp4')"
                    :poster="require('../assets/images/vencasa-snap.png')"
                    webkit-playsinline
                    loop
                    controls
                    playsinline
                  ></video>
                  <div class="play-button-wrapper">
                    <div
                      title="Play video"
                      class="play-gif"
                      id="circle-play-b"
                      style="opacity: 1"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <h2
                style="
                  font-weight: 700;
                  text-transform: inherit;
                  font-family: 'futura';
                "
                class="
                  text-dark text-center
                  pt-4
                  pb-lg-3
                  sec-head
                  d-lg-block d-none
                "
              >
                So what's it all about? <br />
                Find out here
              </h2>
              <p class="text-center" style="font-weight: 650">
                Sign up for our referral program today and get <br />
                £50 every time someone uses your code, <br />
                and they get 50% off too
              </p>
              <div class="text-center">
                <router-link
                  to="/register"
                  class="btn btn-success px-5 py-2"
                >
                  Sign Up Today
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="main">
        <div class="container">
          <div class="row no-gutters">
            <div class="col-lg-8 col-12 my-5">
              <div class="row cart no-gutters pl-lg-0 pr-lg-3 px-2 py-4">
                <div class="col-lg-9 col-12 order-lg-first order-last">
                  <h3 class="text-center pt-3 pb-1">
                    We're not just another online agent!
                  </h3>
                  <p class="text-center">
                    Vencasa is a completely new way to sell your home online
                    yourself, without the need for a middleman. You retain full
                    control, deal directly with buyers, and save thousands in
                    commission.
                  </p>
                </div>
                <div class="col-lg-3 col-12 order-lg-last order-first my-auto">
                  <div class="text-center mt-lg-0 mt-3">
                    <img
                      class="img2"
                      :src="require('../assets/images/idea_bulb.png')"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row no-gutters justify-content-end">
            <div class="col-lg-8 col-12 mb-5">
              <div class="row cart no-gutters pr-lg-0 pl-lg-2 px-2 pt-4">
                <div class="col-lg-3 col-12 mt-3">
                  <div class="text-center">
                    <img
                      class="img2"
                      :src="require('../assets/images/v2_240.png')"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-lg-9 col-12">
                  <h3 class="text-center pt-3 pb-1 w-100">
                    Did You Know The Average Commission <br />
                    Is 1.4% Plus Vat
                  </h3>
                  <p class="text-center">
                    The average commission today in the UK is 1.4% of the final
                    sale price of your property, plus VAT at 20%. Vencasa
                    charges you a flat rate up front, regardless of the price of
                    your property. This saves you thousands in commission!
                  </p>
                  <h4 class="text-center pt-3 pb-1">
                    Don't believe us let's look at an example:
                  </h4>
                  <p class="text-center height-p">
                    The average house price is &#163;255,000 <br />
                    Using a traditional agent you will pay &#163;4284 <br />
                    Using Vencasa that amount would cover: <br />
                    Vencasa's Listing fee <br />
                    The stamp duty on another &#163;255,000 property <br />
                    Solicitors fees <br />
                    And <br />
                    You would still have around &#163;400 in your pocket to
                    spend on removals.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-lg-8 col-12 mb-5">
              <div class="row cart no-gutters pl-lg-0 pr-lg-3 px-2 py-4">
                <div class="col-lg-9 col-12 order-lg-first order-last">
                  <h3 class="text-center pt-3 pb-1">Cut Out The Middle Man</h3>
                  <p class="text-center">
                    You maintain complete control over your sale, no more
                    waiting 2 days to hear back about an offer. Deal directly
                    with buyers using our secure internal messaging system.
                  </p>
                </div>
                <div class="col-lg-3 col-12 order-lg-last order-first my-auto">
                  <div class="text-center py-3">
                    <img
                      class="img2"
                      :src="require('../assets/images/v2_328.png')"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row no-gutters justify-content-end">
            <div class="col-lg-8 col-12 mb-5">
              <div class="row cart no-gutters pr-lg-0 pl-lg-3 px-2 pt-2">
                <div class="col-lg-3 col-12 my-auto">
                  <div class="text-center py-3">
                    <img
                      class="img2"
                      :src="require('../assets/images/v2_333.png')"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-lg-9 col-12">
                  <h3 class="text-center pt-3 pb-1 w-100">
                    Thousands of Potential Buyers
                  </h3>
                  <p class="text-center">
                    Our advertising is generating hundreds of thousands of new
                    potential buyers every month.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 2nd Section -->

        <div id="how-it-work" class="container mb-5">
          <div class="section2">
            <div class="row no-gutters">
              <div class="col-12">
                <h2
                  style="font-weight: 700; text-transform: capitalize"
                  class="text-light text-center pt-4 mt-1 pb-lg-3 sec-head"
                >
                  How Does It Work?
                </h2>
              </div>
              <div class="col-lg-6 col-12 my-2">
                <div class="row no-gutters">
                  <div class="col-lg-8 col-12 order-lg-first order-2">
                    <h3 class="text-center text-light d-lg-block d-none">
                      Step1.
                    </h3>
                    <p class="text-light text-center">
                      Sign Up For An Account Below To Get <br />
                      Started
                    </p>
                    <div class="text-center">
                      <router-link
                        to="/register"
                        class="btn btn-success px-5"
                      >
                        Sign Up
                      </router-link>
                    </div>
                  </div>
                  <div class="col-lg-8 col-12 order-0 d-lg-none d-block">
                    <h3 class="text-center text-light py-3">Step1.</h3>
                  </div>
                  <div class="col-lg-4 col-12 order-lg-last order-1 my-auto">
                    <div class="text-center">
                      <img
                        class="img2"
                        :src="require('../assets/images/v2_349.png')"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters justify-content-end">
              <div class="col-lg-6 col-12 my-2">
                <div class="row no-gutters">
                  <div class="col-lg-8 col-12 order-0 d-lg-none d-block">
                    <h3 class="text-center text-light py-3">Step2.</h3>
                  </div>
                  <div class="col-lg-4 col-12 order-lg-first order-1 my-auto">
                    <div class="text-center">
                      <img
                        class="img2"
                        :src="require('../assets/images/v2_366.png')"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col-lg-8 col-12 order-lg-last order-2">
                    <h3 class="text-center text-light d-lg-block d-none">
                      Step2.
                    </h3>
                    <p class="text-light text-center">
                      Tell us about your property. <br />
                      Follow our simple step by step <br />
                      guide that will lead you effortlessly <br />
                      through the listings process.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-lg-6 col-12 my-2">
                <div class="row no-gutters">
                  <div class="col-lg-8 col-12 order-lg-first order-2">
                    <h3 class="text-center text-light d-lg-block d-none">
                      Step3.
                    </h3>
                    <p class="text-light text-center">
                      Make payment. <br />
                      One simple up front set free. <br />
                      Listing will remain on our site until sold.
                    </p>
                  </div>
                  <div class="col-lg-8 col-12 order-0 d-lg-none d-block">
                    <h3 class="text-center text-light py-3">Step3.</h3>
                  </div>
                  <div class="col-lg-4 col-12 order-lg-last order-1 my-auto">
                    <div class="text-center">
                      <img
                        class="img2"
                        :src="require('../assets/images/v2_369.png')"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters justify-content-end">
              <div class="col-lg-6 col-12 my-2">
                <div class="row no-gutters">
                  <div class="col-lg-8 col-12 order-0 d-lg-none d-block">
                    <h3 class="text-center text-light py-3">Step4.</h3>
                  </div>
                  <div class="col-lg-4 col-12 order-lg-first order-1 my-auto">
                    <div class="text-center">
                      <img
                        class="img2"
                        :src="require('../assets/images/v2_372.png')"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col-lg-8 col-12 order-lg-last order-2">
                    <h3 class="text-center text-light d-lg-block d-none">
                      Step4.
                    </h3>
                    <p class="text-light text-center">
                      Show Off Your Property. <br />
                      Share your listing with friends on <br />
                      social media. <br />
                      Message buyers directly and arrange <br />
                      viewings.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-lg-6 col-12 my-2">
                <div class="row no-gutters">
                  <div class="col-lg-8 col-12 order-lg-first order-2">
                    <h3 class="text-center text-light d-lg-block d-none">
                      Step5.
                    </h3>
                    <p class="text-light text-center">
                      Accept an offer. <br />
                      Once you are happy, accept an offer <br />
                      through our internal messaging system <br />
                      and hand over the legal bit to the <br />
                      solicitors.
                    </p>
                  </div>
                  <div class="col-lg-8 col-12 order-0 d-lg-none d-block">
                    <h3 class="text-center text-light py-3">Step5.</h3>
                  </div>
                  <div class="col-lg-4 col-12 order-lg-last order-1 my-auto">
                    <div class="text-center">
                      <img
                        class="img2"
                        :src="require('../assets/images/v2_378.png')"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters justify-content-end pb-4 mb-1">
              <div class="col-lg-6 col-12 my-2">
                <div class="row no-gutters">
                  <div class="col-lg-8 col-12 order-0 d-lg-none d-block">
                    <h3 class="text-center text-light py-3">Step6.</h3>
                  </div>
                  <div class="col-lg-4 col-12 order-lg-first order-1 my-auto">
                    <div class="text-center">
                      <img
                        class="img2"
                        :src="require('../assets/images/v2_381.png')"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col-lg-8 col-12 order-lg-last order-2">
                    <h3 class="text-center text-light d-lg-block d-none">
                      Step6.
                    </h3>
                    <p class="text-light text-center">
                      Relax <br />
                      Sit back and think about how to spend <br />
                      your hard earned savings.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container pb-4">
          <div class="bg-dgreen">
            <div class="row">
              <div class="col-sm-12 d-md-none d-block order-0 pt-4 pb-3 px-3">
                <h2
                  style="font-weight: 700; text-transform: capitalize"
                  class="text-light text-center sec-head"
                >
                  Helping the Environment
                </h2>
              </div>
              <div
                class="
                  col-sm-12
                  text-center
                  my-md-4
                  order-md-0 order-1
                  pt-2
                  pb-2
                "
              >
                <img
                  class="img3"
                  :src="require('../assets/images/v3_57.png')"
                  alt=""
                />
              </div>

              <div class="col-sm-12 order-md-1 order-2 pb-2">
                <h2
                  style="font-weight: 700; text-transform: capitalize"
                  class="text-light text-center sec-head d-md-block d-none"
                >
                  Helping the Environment
                </h2>
                <div class="mt-4">
                  <p class="text-light text-center">
                    Vencasa is committed to contributing to the protection of
                    the environment. <br /> That is why
                    we have partnered with <a style="color: white" target="_blank" href="https://ecologi.com/">ecologi.com</a>
                  </p>
                  <p class="text-light text-center">
                    We plant 16 trees for each listing and offer you the
                    opportunity to do even <br />
                    more by having the option to offset your move.
                  </p>
                  <p class="text-light text-center">
                    By planting trees, you are not only fighting the C02
                    problem, <br />
                    but also providing a home for animals for years to come.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container my-4">
          <div class="row justify-content-end mx-0">
            <div class="col-md-6 d-lg-block d-none my-auto mr-5">
              <img :src="require('../assets/images/dino.png')" alt="" />
            </div>
            <div class="col-lg-5 border border-dark">
              <div class="text-center">
                <h3 class="mt-2 account-h3" style="font-weight: 700">
                  Create An Account to Get Started
                </h3>
                <p
                  class="mt-0 mb-2 text-center"
                  style="word-spacing: 5px; font-weight: 600"
                >
                  Once you sign up we will send you a unique referral code.
                  Share this code with your friends and you will receive
                  &#163;50 every time someone uses it to pay for a listing
                  <br />
                  They will also receive 50% off
                </p>
                <div
                  class="
                    access_social
                    mx-lg-4 mx-md-2 mx-0
                    px-lg-5 px-md-4 px-4
                  "
                >
                  <!-- <a
                        @click="facebookLogin()"
                        href="#0"
                        class="social_bt facebook"
                        >Continue with Facebook</a
                      > -->
                  <a @click="googleLogin()" href="#0" class="social_bt google">
                    &nbsp; &nbsp; &nbsp; Continue with Google</a
                  >
                </div>
                <h4 style="font-weight: 700">
                  OR <br />
                  Sign Up <br />
                  Manually
                </h4>
              </div>
              <div class="mx-4 px-lg-5 px-md-2 px-0">
                <form @submit.prevent="">
                  <div class="form-group">
                    <label><strong> Username</strong></label>
                    <input
                      :class="
                        $v.username.$dirty && !$v.username.required
                          ? 'error-border'
                          : ''
                      "
                      v-model="username"
                      class="form-control border-dark"
                      type="text"
                    />
                    <span
                      class="error"
                      v-if="$v.username.$dirty && !$v.username.required"
                      >This field is required!</span
                    >
                    <span class="error" v-if="usernameError"
                      >This username has already been taken</span
                    >
                  </div>

                  <div class="form-group">
                    <label><strong>First Name</strong></label>
                    <input
                      :class="
                        $v.firstName.$dirty && !$v.firstName.required
                          ? 'error-border'
                          : ''
                      "
                      v-model="firstName"
                      class="form-control border-dark"
                      type="text"
                    />
                    <span
                      class="error"
                      v-if="$v.firstName.$dirty && !$v.firstName.required"
                      >This field is required!</span
                    >
                  </div>
                  <div class="form-group">
                    <label><strong>Last Name</strong></label>
                    <input
                      :class="
                        $v.lastName.$dirty && !$v.lastName.required
                          ? 'error-border'
                          : ''
                      "
                      v-model="lastName"
                      class="form-control border-dark"
                      type="text"
                    />
                    <span
                      class="error"
                      v-if="$v.lastName.$dirty && !$v.lastName.required"
                      >This field is required!</span
                    >
                  </div>
                  <div class="form-group email">
                    <label><strong>Email</strong></label>
                    <input
                      :class="
                        $v.email.$dirty && !$v.email.required
                          ? 'error-border'
                          : ''
                      "
                      v-model="email"
                      class="form-control border-dark"
                      type="email"
                    />
                    <span
                      class="error"
                      v-if="
                        ($v.email.$dirty && !$v.email.required) ||
                        !$v.email.email
                      "
                      >{{
                        `${
                          !$v.email.required
                            ? "This field is required!"
                            : "Email format is incorrect!"
                        }`
                      }}</span
                    >
                    <span class="error" v-if="emailError"
                      >This email has already been taken</span
                    >
                  </div>
                  <div class="form-group">
                    <label><strong>Password</strong></label>
                    <input
                      :class="
                        ($v.password.$dirty && !$v.password.required) ||
                        ($v.password.$dirty && !$v.password.minLength)
                          ? 'error-border'
                          : ''
                      "
                      v-model="password"
                      class="form-control border-dark"
                      type="password"
                      id="password1"
                    />
                    <span
                      class="error"
                      v-if="$v.password.$dirty && !$v.password.required"
                      >This field is required!</span
                    >
                    <span
                      class="error"
                      v-if="$v.password.$dirty && !$v.password.minLength"
                      >Password must be 8 digits long</span
                    >
                  </div>
                  <div class="form-group">
                    <label><strong>Confirm Password</strong></label>
                    <input
                      :class="
                        !$v.passwordConfirmation.sameAsPassword
                          ? 'error-border'
                          : ''
                      "
                      v-model="passwordConfirmation"
                      class="form-control border-dark"
                      type="password"
                      id="password2"
                    />
                    <span
                      class="error"
                      v-if="!$v.passwordConfirmation.sameAsPassword"
                      >passwords must match</span
                    >
                  </div>
                  <div id="pass-info" class="clearfix"></div>
                  <div class="d-flex justify-content-center">
                    <button
                      @click="register"
                      type="button"
                      class="btn btn-success btn-lg btn-block"
                    >
                      Sign Up Now
                    </button>
                  </div>
                  <div class="text-center mt-2 mb-4">
                    <h6 class="dis-block my-2">Already have an account?</h6>
                    <router-link to="/login" class="pl-1 link" href=""
                      >sign in</router-link
                    >
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <a data-scroll href="#wrapper" class="go-up" style="display: inline"
          ><i class="fa fa-angle-double-up" aria-hidden="true"></i
        ></a>
      </div>
      <div class="container blog-section bg-white">
        <div class="sec-title">
          <h2 style="font-weight: 700" class="mt-5">Articles &amp; Tips</h2>
          <p style="font-family: 'Montserrat', sans-serif; font-weight: 600">
            Read the latest news from our blog.
          </p>
        </div>
        <div class="news-wrap mb-5">
          <div class="row">
            <articles
              class="hover"
              v-for="blog in blogs ? blogs.slice(0, 3) : []"
              :key="blog.id"
              :blog="blog"
            />
          </div>
        </div>
      </div>
      <a data-scroll href="#wrapper" class="go-up" style="display: inline"
        ><i class="fa fa-angle-double-up" aria-hidden="true"></i
      ></a>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
const articles = () =>
  import(/* webpackChunkName: "pyioifsda9" */ "../components/articles");
import "vue-range-component-fixed/dist/vue-range-slider.css";
import VueRangeSlider from "vue-range-component-fixed";
import { formatter } from "../utils";
import Multiselect from "vue-multiselect";
import $ from "jquery";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    username: {
      required,
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
    passwordConfirmation: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  data() {
    return {
      hasClicked: false,
      usernameError: false,
      emailError: false,
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      gardenOptions: [],
      noOfBathrooms: [],
      noOfBedrooms: [],
      radiusOptions: [],
      swimmingPoolOptions: [],
      parkingOptions: [],
      garageOptions: [],
      types: [],
      canBeMultiple: ["type", "garage", "parking", "swimmingPool"],
      searchOpen: false,
      rangeValues: [
        0, 50000, 60000, 70000, 80000, 90000, 100000, 120000, 140000, 160000,
        180000, 200000, 220000, 240000, 260000, 280000, 300000, 320000, 340000,
        360000, 380000, 400000, 420000, 440000, 460000, 480000, 500000, 600000,
        700000, 800000, 900000, 1000000, 1250000, 1500000, 1750000, 2000000,
        2250000, 2500000, 2750000, 3000000, 3250000, 3500000, 3750000, 4000000,
        4250000, 4500000, 4750000, 5000000, 6000000, 7000000, 8000000, 9000000,
        10000000,
      ],
      query: {},
      value: [0, 0],
      responsive: {
        0: {
          items: 1,
          center: false,
        },
        480: {
          items: 1,
          center: false,
        },
        520: {
          items: 2,
          center: false,
        },
        600: {
          items: 2,
          center: false,
        },
        768: {
          items: 2,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 4,
        },
        1366: {
          items: 4,
        },
        1400: {
          items: 4,
        },
      },
    };
  },
  computed: {
    _() {
      return _;
    },
    sellerSavePercentage() {
      let matched = this.configurations.find((configuration) => {
        return configuration.key == "sellerSavePercentageAmount";
      });
      return matched ? formatter(matched.val).toString() : "";
    },
    ...mapGetters({
      blogs: "getBlogs",
      authUser: "auth",
      configurations: "getConfigurations",
      check: "check",
      myBuying: "getMyBuying",
      userProperties: "getUserProperties",
    }),
  },
  components: {
    articles,
    VueRangeSlider,
    Multiselect,
  },
  methods: {
    formatter: formatter,
    async register() {
      if (this.$v.$invalid) {
        if (this.$v.firstName.$invalid) this.$v.firstName.$touch();
        if (this.$v.lastName.$invalid) this.$v.lastName.$touch();
        if (this.$v.username.$invalid) this.$v.username.$touch();
        if (this.$v.email.$invalid) this.$v.email.$touch();
        if (this.$v.password.$invalid) this.$v.password.$touch();
        if (this.$v.passwordConfirmation.$invalid)
          this.$v.passwordConfirmation.$touch();
      } else {
        this.hasClicked = true;
        this.$store.commit("setShowLoader", true);
        const data = {
          name: this.firstName + " " + this.lastName,
          email: this.email,
          password: this.password,
          user_name: this.username,
          password_confirmation: this.passwordConfirmation,
        };
        await this.$store.dispatch("register", data).then((res) => {
          this.$store.commit("setShowLoader", false);
          if (res == true) {
            if (this.ref_code) {
              this.$store
                .dispatch("sendRequestIfCookie", this.ref_code)
                .then((res) => {
                  if (res == true) {
                    this.$router.push("/email-verification");
                  }
                });
            } else this.$router.push("/email-verification");
          } else if (res.data.code == 422 && res.data.errors.email) {
            this.emailError = true;
            document
              .querySelector(".email")
              .scrollIntoView({ behavior: "smooth" });
          } else if (res.data.code == 422 && res.data.errors.user_name) {
            this.usernameError = true;
            document
              .querySelector(".username")
              .scrollIntoView({ behavior: "smooth" });
          } else if (res.data.code == 422 && res.data.errors.password) {
            alert("The passwords must match");
          }
        });
      }
    },
    navigate(path) {
      this.$router.push(path);
    },
    takeToHowItWorks() {
      document
        .querySelector("#how-it-works")
        .scrollIntoView({ behavior: "smooth" });
    },
    checkForChange() {
      this.isAdvancedSearchOpen();
    },
    isAdvancedSearchOpen() {
      this.searchOpen = !document
        .querySelector(".full-filter")
        ?.classList.contains("filter-block");
    },
    addToQuery(param, event) {
      console.log({ param, event });
      if (typeof event == "object") {
        if (param == "price") {
          let obj = { priceFrom: "", priceTo: "" };
          for (let i = 0; i < Object.keys(obj).length; i++) {
            this.query[Object.keys(obj)[i]] = event[i];
          }
        } else if (this.canBeMultiple.includes(param)) {
          let queryString = "";
          for (let i = 0; i < event.length; i++) {
            queryString =
              queryString + `${event[i].value}${event[i + 1] ? "," : ""}`;
          }
          this.query[param] = queryString;
        }
        return;
      }
      this.query[param] = event;
    },
    search() {
      this.query["perPage"] = "10";
      this.$router.push({ path: "listing", query: this.query });
    },
    initialized() {
      console.log("initialized");
    },
    sellerSaveShow() {
      let matchedConfig = this.configurations.find((configuration) => {
        return configuration.key == "sellerSavePercentageShow";
      });
      return _.get(matchedConfig, "val", false);
    },
    findIfActive(config) {
      if (!_.isEmpty(this.configurations)) {
        let matchedConfig = this.configurations.find((configuration) => {
          return configuration.key == config;
        });
        return matchedConfig ? matchedConfig.val.isActive : 0;
      } else return 0;
    },
    resize() {
      if ($(window).width() < 700) {
        $(".checkboxes").removeClass("display");
      } else if ($(window).width() > 700) {
        $(".checkboxes").addClass("display");
      }
    },
  },
  destroyed() {
    document.querySelector("body").removeEventListener("click", (e) => {
      if (
        !e.target?.classList.contains("advanceSlow") &&
        document
          .querySelector(".full-filter")
          .classList.contains("filter-block")
      ) {
        e.stopPropagation();
        document.querySelector(".advanceSlow").click();
      }
    });
  },
  async mounted() {
    this.resize();
    this.$nextTick(() => {
      $(".dropdown-filter").on("click", function () {
        $(".explore__form-checkbox-list").toggleClass("filter-block");
      });
      document.querySelector("body").addEventListener("click", (e) => {
        if (e.target) {
          let found = e.path.find((p) => {
            return p.classList ? p.classList?.contains("full-filter") : false;
          });
          if (
            !e.target?.classList.contains("advanceSlow") &&
            (document.querySelector(".full-filter")
              ? document
                  .querySelector(".full-filter")
                  .classList.contains("filter-block")
              : false) &&
            !found
          ) {
            e.stopPropagation();
            document.querySelector(".advanceSlow").click();
          }
        }
      });
      const video = document.getElementById("video");
      const circlePlayButton = document.getElementById("circle-play-b");

      function togglePlay() {
        if (video.paused || video.ended) {
          video.play();
        } else {
          video.pause();
        }
      }

      // circlePlayButton.addEventListener("click", togglePlay);
      // video.addEventListener("playing", function () {
      //   circlePlayButton.style.opacity = 0;
      // });
      // video.addEventListener("pause", function () {
      //   circlePlayButton.style.opacity = 1;
      // });
    });
    if (_.isEmpty(this.authUser)) {
      await this.$store.dispatch("getUser");
    }
    if (_.isEmpty(this.configurations)) {
      await this.$store.dispatch("getConfigurations");
    }
    if (!this.myBuying.length && this.check)
      await this.$store.dispatch("getMyBuying");
    if (!this.userProperties.length && this.check)
      await this.$store.dispatch("getUserProperties");
    if (!this.blogs) {
      this.$store.dispatch("blogs", { page: 1, perPage: 10 });
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/search-form.css"></style>
<style scoped src="../assets/css/search.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/colors/black.css"></style>

<style scoped>
div >>> .multiselect__option--selected {
  background: #548f4d;
  color: white;
  font-weight: 500;
}

div >>> .multiselect__option--selected.multiselect__option--highlight {
  background: #548f4d;
  color: white;
  font-weight: 500;
}

div >>> .multiselect__option--highlight {
  background: white;
  color: #4f5f73;
}

.w-124 {
  width: 225px !important;
}

.margin-rightt {
  margin-right: 18px !important;
}

.w1001 {
  width: 100% !important;
}

div >>> .multiselect__select {
  right: 19px !important;
}

div >>> .multiselect__tags {
  padding-left: 20px !important;
  margin-left: 0px !important;
  margin-right: 18px !important;
  width: 100% !important;
}

@media (min-width: 1200px) {
  .res-big-display {
    display: none;
  }
}

@media (min-width: 760px) and (max-width: 1900px) {
  .pfont {
    font-size: 16px !important;
  }
}

@media (min-width: 1901px) and (max-width: 3000px) {
  .pfont {
    font-size: 25px !important;
  }
}

@media (max-width: 700px) {
  .res-butt {
    display: none !important;
  }

  .w-124 {
    width: 100% !important;
  }

  .resp-margin-topp {
    margin-top: 7px !important;
  }

  .res-styles {
    letter-spacing: -0.4px !important;
  }

  .res-margin-seting {
    margin-bottom: 6% !important;
  }

  .w1001 {
    width: auto !important;
  }
  .res-pl-0 {
    padding-left: 0px !important;
  }

  .margin-rightt {
    margin-right: 0px !important;
  }

  .res-video {
    height: 200px !important;
    width: 100% !important;
  }

  .rld-single-input {
    width: 100% !important;
  }

  div >>> .multiselect {
    width: 100% !important;
    padding-left: 0px !important;
    margin: 0 !important;
  }
  .homepage-5 .rld-main-search {
    height: 250px !important;
  }

  .res-marginr-20 {
    margin-right: 20px !important;
  }

  .res-display-unset {
    display: unset !important;
  }
  .res-plr-0 {
    padding-right: 0px !important;
  }
  .res-p {
    padding-top: 187px;
  }
  .res-swinign {
    margin-left: 14px !important;
    width: 94% !important;
  }

  .res-s-dnone {
    display: none;
  }
  .res-bimg {
    background-size: 100% 268px !important;
    height: 795px !important;
  }

  .res_remove_ml {
    margin-left: 0px !important;
  }
  .res-width {
    width: 100% !important;
  }
  .float-rights {
    text-align: center !important;
    float: none !important;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    margin-left: 7px !important;
    width: 95% !important;
    padding-left: 5% !important;
    padding-right: 265px !important;
  }
  .property-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  div >>> .height0 {
    height: 360px !important;
  }
  .wide {
    width: 100% !important;
  }
  .res-plt {
    margin-top: 70px !important;
  }
  .res-button-how {
    margin: 0px !important;
    width: 250px !important;
    padding: 13px !important;
  }

  .res-button-api {
    width: 320px !important;
    padding: 12px !important;
    margin: 13px !important;
  }

  div >>> .multiselect__tags {
    padding-right: 0px;

    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 10px !important;

    padding-left: 20px !important;
    margin-left: 0px !important;
    margin-right: 18px !important;
  }

  div >>> .multiselect__select {
    left: 63% !important;
  }

  .res-search-button-new {
    padding: 0px !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .res-mrl-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  div >>> .multiselect {
    padding-left: 0px !important;
  }

  .res-postal-code {
    margin-right: 0px !important;
    /* width: 289px !important;
    margin-left: 24px !important;
    padding-left: 32px !important; */
  }
  .res-threedot {
    margin-left: 19px !important;
    width: 89.9% !important;
  }

  .res-margin-save-set {
    margin-top: 15% !important;
  }

  .sec-title {
    padding-bottom: 0px !important;
  }

  .res-mt-2 {
    margin-top: 40px !important;
  }

  .admin {
    display: none !important;
  }

  .res-threedot span {
    padding-right: 24px !important;
  }

  .res-bed-icon {
    position: absolute;
    left: 12.5% !important;
    z-index: 9999;
    top: 112px;
    top: 66% !important;
  }

  .res-bathroom-icon {
    left: 10% !important;
  }

  .res-garden-icon {
    left: 10% !important;
  }

  .res-parking-icon {
    left: 10% !important;
  }

  .res-swmin-icon {
    left: 10% !important;
  }

  .res-pl-0 {
    padding-left: 0px !important;
  }

  .res-button-prop {
    padding: 15px 35px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 400px) and (max-width: 700px) {
  .property-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .dropdown-filter span:after {
    margin-left: 198px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    width: 95% !important;

    padding-right: 24px !important;
    margin: 0;
    padding-left: 78% !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 350px) and (max-width: 430px) {
  .homepage-5 .parallax-searchs.home17 {
    height: 600px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-s-dnone {
    display: none;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    width: 95% !important;
    padding-right: 24px !important;
    margin: 0;
    padding-left: 78% !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 700px) and (max-width: 786px) {
  .homepage-5 .parallax-searchs.home17 {
    height: 394px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-s-dnone {
    display: none;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    margin-left: 7px !important;
    width: 95% !important;
    padding-left: 5% !important;
    padding-right: 85% !important;
  }
  .res-display {
    display: none;
  }
}

div >>> .vs--searchable .vs__dropdown-toggle {
  cursor: text;
  width: 200px !important;
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 0px 8px rgb(255 255 255 / 20%);
  height: 48px;
  padding: 0 34px 0 34px;
  line-height: 47px;
  font-weight: 400;
  color: #000;
  font-family: "Lato", sans-serif;
  background: white;
  word-wrap: normal;
  margin-right: 0px !important;
  margin-left: 15px;
}

.setwidth-all {
  width: 200px !important;
}

.display {
  display: flex !important;
}
.property-margin {
  margin-right: 0px !important;
  margin-left: 15px;
}
.remove-margin {
  margin-bottom: 0px !important;
}

.btn-yellow:hover {
  color: #272b32 !important;
}
.bsd:hover {
  border: 1px solid #548f4d !important;
}

div >>> .slider-process {
  max-width: 1014px;
}

div >>> .slider {
  max-width: 1010px;
}

.float-rights {
  float: right;
}

div >>> .slider-dot .slider-always {
  transform: translateX(998px) !important;
}
.mar-settg {
  margin-right: -7px;
  margin-left: -7px;
}

.fieldBed {
  margin-right: 0px !important;
  margin-left: 15px !important;
  height: 48px !important;
  line-height: 47px !important;
  width: 100%;
  padding: 12px 56px 12px 20px !important;
  border: 1px solid #ebebeb !important;
  transition: all 0.4s ease !important;
  position: relative !important;
  border-radius: 8px !important;
}
.w-196 {
  width: 196px !important;
}
.progress-container {
  width: 80%;
  margin: 2em auto;
}
.progress-steps {
  counter-reset: step;
}
.progress-steps li {
  list-style-type: none;
  width: 20%;
  float: left;
  font-size: 1.5rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
}
.progress-steps li:before {
  width: 4.5em;
  height: 4.5em;
  content: counter(step);
  counter-increment: step;
  line-height: 4em;
  border: 6px solid green;
  display: block;
  text-align: center;
  margin: 10px auto 0.7em auto;
  border-radius: 50%;
  background-color: white;
  padding-top: 2px;
}
.progress-steps li:after {
  width: 100%;
  height: 6px;
  content: "";
  position: absolute;
  background-color: green;
  top: 2.6em;
  left: -50%;
  z-index: -1;
}
.progress-steps li:first-child:after {
  content: none;
}
.list-des {
  font-size: 17px;
  font-weight: 600;
}
.bedroomAdvance {
  color: rgb(97, 140, 87);
  font-size: 25px;
  height: 22px;
  width: 22px;
  padding-right: 2px;
  margin-top: -7px;
}
.pedrem {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.m30 {
  margin-bottom: 30px;
}
.res-llist {
  width: 100%;
  padding-left: 8px !important;
}

div >>> .homepage-5 .btn-yellow:hover {
  background: #548f4d !important;
  background-color: #548f4d !important;
}

div >>> .multiselect__placeholder {
  padding: 0px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
}

div >>> .multiselect__input {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
}
div >>> .multiselect__tags {
  margin-left: 15px;
  height: 50px;
  max-width: 202px;
  width: 202px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
  padding-top: 13px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  box-shadow: 0 0 0 8px rgb(255 255 255 / 20%);
  padding-left: 28px;
}

div >>> .multiselect__select:before {
  top: 75%;
}

::placeholder {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
}

div >>> .multiselect__placeholder {
  padding-left: 4px;
}
@media (min-width: 1200px) {
  .res-big-display {
    display: none;
  }

  .res-show-mobile {
    display: none !important;
  }
}

@media (max-width: 499px) and (max-width: 700px) {
  .res-butt {
    display: none !important;
  }
  .res-p {
    padding-top: 187px;
  }
  .res-swinign {
    margin-left: 14px !important;
    width: 94% !important;
  }

  .res-video {
    height: 200px !important;
    width: 100% !important;
  }
  .res-login {
    width: 100% !important;
    margin: 0px !important;
  }

  .res-s-dnone {
    display: none;
  }
  .res-bimg {
    background-size: 100% 268px !important;
    height: 795px !important;
  }

  .res_remove_ml {
    margin-left: 0px !important;
  }
  .res-width {
    width: 100% !important;
  }
  .float-rights {
    text-align: center !important;
    float: none !important;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    margin-left: 7px !important;
    width: 95% !important;
    padding-left: 5% !important;
    padding-right: 265px !important;
  }
  .property-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  div >>> .height0 {
    height: 360px !important;
  }
  .wide {
    width: 100% !important;
  }
  .res-plt {
    margin-top: 70px !important;
  }
  .res-button-how {
    width: 250px !important;
    padding: 13px !important;
    margin: 0px !important;
  }

  .res-button-api {
    width: 320px !important;
    padding: 12px !important;
    margin: 13px !important;
  }

  div >>> .multiselect__tags {
    padding-right: 0px;
    margin-right: 0px;
    width: 290px !important;
    max-width: 290px !important;
    margin-bottom: 10px !important;
  }
  div >>> .multiselect__select {
    left: 64% !important;
  }

  div >>> .multiselect {
    padding-left: 10px !important;
  }

  .res-postal-code {
    width: 289px !important;
    margin-left: 24px !important;
    padding-left: 32px !important;
  }
  .res-threedot {
    margin-left: 19px !important;
    width: 89.9% !important;
  }

  .res-margin-save-set {
    margin-top: 15% !important;
  }

  .sec-title {
    padding-bottom: 0px !important;
  }

  .res-mt-2 {
    margin-top: 40px !important;
  }

  .admin {
    display: none !important;
  }

  .res-threedot span {
    padding-right: 24px !important;
  }

  .res-bed-icon {
    position: absolute;
    left: 12.5% !important;
    z-index: 9999;
    top: 112px;
    top: 66% !important;
  }

  .res-bathroom-icon {
    left: 10% !important;
  }

  .res-garden-icon {
    left: 10% !important;
  }

  .res-parking-icon {
    left: 10% !important;
  }

  .res-swmin-icon {
    left: 10% !important;
  }

  .res-pl-0 {
    padding-left: 0px !important;
  }

  .res-button-prop {
    padding: 15px 35px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 400px) and (max-width: 700px) {
  .property-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .dropdown-filter span:after {
    margin-left: 198px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    width: 95% !important;

    padding-right: 24px !important;
    margin: 0;
    padding-left: 78% !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 350px) and (max-width: 430px) {
  .homepage-5 .parallax-searchs.home17 {
    height: 802px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-s-dnone {
    display: none;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    width: 95% !important;

    padding-right: 24px !important;
    margin: 0;
    padding-left: 78% !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 700px) and (max-width: 786px) {
  .homepage-5 .parallax-searchs.home17 {
    height: 394px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-s-dnone {
    display: none;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    margin-left: 7px !important;
    width: 95% !important;
    padding-left: 5% !important;
    padding-right: 85% !important;
  }
  .res-display {
    display: none;
  }
}
div >>> .vs--searchable .vs__dropdown-toggle {
  cursor: text;
  width: 200px !important;
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 0px 8px rgb(255 255 255 / 20%);
  height: 48px;
  padding: 0 34px 0 34px;
  line-height: 47px;
  font-weight: 400;
  color: #000;
  font-family: "Lato", sans-serif;
  background: white;
  word-wrap: normal;
  margin-right: 0px !important;
  margin-left: 15px;
}

.setwidth-all {
  width: 200px !important;
}

.display {
  display: flex !important;
}
.property-margin {
  margin-right: 0px !important;
  margin-left: 15px;
}
.remove-margin {
  margin-bottom: 0px !important;
}

.btn-yellow:hover {
  color: #272b32 !important;
}
.bsd:hover {
  border: 1px solid #548f4d !important;
}

div >>> .slider-process {
  max-width: 1014px;
}

div >>> .slider {
  max-width: 1010px;
}

.float-rights {
  float: right;
}

div >>> .slider-dot .slider-always {
  transform: translateX(998px) !important;
}
.mar-settg {
  margin-right: -7px;
  margin-left: -7px;
}

.fieldBed {
  margin-right: 0px !important;
  margin-left: 15px !important;
  height: 48px !important;
  line-height: 47px !important;
  width: 100%;
  padding: 12px 56px 12px 20px !important;
  border: 1px solid #ebebeb !important;
  transition: all 0.4s ease !important;
  position: relative !important;
  border-radius: 8px !important;
}
.w-196 {
  width: 196px !important;
}
.progress-container {
  width: 80%;
  margin: 2em auto;
}
.progress-steps {
  counter-reset: step;
}
.progress-steps li {
  list-style-type: none;
  width: 20%;
  float: left;
  font-size: 1.5rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
}
.progress-steps li:before {
  width: 4.5em;
  height: 4.5em;
  content: counter(step);
  counter-increment: step;
  line-height: 4em;
  border: 6px solid green;
  display: block;
  text-align: center;
  margin: 10px auto 0.7em auto;
  border-radius: 50%;
  background-color: white;
  padding-top: 2px;
}
.progress-steps li:after {
  width: 100%;
  height: 6px;
  content: "";
  position: absolute;
  background-color: green;
  top: 2.6em;
  left: -50%;
  z-index: -1;
}
.progress-steps li:first-child:after {
  content: none;
}
.list-des {
  font-size: 17px;
  font-weight: 600;
}
.bedroomAdvance {
  color: rgb(97, 140, 87);
  font-size: 25px;
  height: 22px;
  width: 22px;
  padding-right: 2px;
  margin-top: -7px;
}
.pedrem {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.m30 {
  margin-bottom: 30px;
}
.res-llist {
  width: 100%;
  padding-left: 8px !important;
}

div >>> .homepage-5 .btn-yellow:hover {
  background: #548f4d !important;
  background-color: #548f4d !important;
}

div >>> .multiselect__placeholder {
  padding: 0px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
}

div >>> .multiselect__input {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
}
div >>> .multiselect__tags {
  margin-left: 15px;
  height: 50px;
  max-width: 202px;
  width: 202px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
  padding-top: 13px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  box-shadow: 0 0 0 8px rgb(255 255 255 / 20%);
  padding-left: 28px;
}

div >>> .multiselect__select:before {
  top: 85%;
}

::placeholder {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
}
div >>> .multiselect__placeholder {
  padding-left: 4px;
}

/* arif kartoot */
/* *{
    box-sizing: content-box;
    margin: 0px;
    padding: 0%;
} */
h2,
h3 {
  font-family: "futura";
}
p {
  font-family: "futura";
}
.cart {
  background-color: rgb(190, 221, 190);
}
.cart h3 {
  font-weight: 700;
}
/* .cart p {
  font-weight: 650;
} */
.cart h4 {
  font-weight: 700;
}
.height-p {
  line-height: 3;
}
p {
  text-align: justify;
  margin: 20px;
}
.img2 {
  max-width: 80%;
  height: auto;
}
.img3 {
  width: 140px;
  height: 140px;
}
.section2 {
  background-color: rgba(0, 0, 0, 0.836);
}
.section3 {
  background-color: rgb(0, 117, 0);
}
.secimage {
  width: 20%;
}
.form-control {
  padding: 0px;
}
h6 {
  display: inline;
}
.btn {
  border-radius: 10px;
  background-color: #53814e;
  border: #53814e;
}
.bg-dgreen {
  background-color: #5a7b51;
}
.main {
  background-image: url(/img/big.6cb53051.webp) !important;
  background-size: cover !important;
  background-position: center center !important;
  background-attachment: fixed !important;
  padding: 0px;
  background-repeat: no-repeat !important;
}
/* @media screen and (max-width: 1199px) {
  .res-video {
    width: 700px !important;
    height: 415px !important;
  }
} */

@media screen and (min-width: 992px) {
  .cart h3 {
    font-size: x-large;
  }
}
@media screen and (max-width: 991.5px) {
  /* .cart h3 {
    font-size: x-large;
  } */
  .img2 {
    width: 100px !important;
    height: 100px;
  }
}
@media screen and (max-width: 767.5px) {
  .height-p {
    line-height: 2;
  }
  .img3 {
    width: 100px !important;
    height: 100px;
  }
}
@media screen and (min-width: 767.5px) {
  .account-h3 {
    font-size: 26px;
  }
}
@media screen and (max-width: 330px) {
  .dis-block {
    display: block;
  }
  .link {
    text-align: center;
  }
}
@media screen and (max-width: 576px) {
  .sec-head {
    font-size: 1.3rem !important;
  }
}
@media screen and (max-width: 1199px) {
  .w-124 {
    width: 175px !important;
    margin-right: 16px !important;
  }
}
@media (min-width: 991px) and (max-width: 1025px) {
  .rld-single-input {
    margin-right: 58px !important;
  }
}
@media (min-width: 767px) and (max-width: 990px) {
  .rld-single-input {
    margin-right: 19px !important;
  }
  .res-search-button-new {
    margin-left: 23px !important;
  }
}
@media (min-width: 701px) and (max-width: 766px) {
  .res_remove_ml {
    margin-left: 11px !important;
    width: 124% !important;
  }
  .res-search-button-new {
    margin-left: 22px !important;
  }
  .rld-single-input {
    margin-right: 68px !important;
  }
}
@media (min-width: 530px) and (max-width: 700px) {
  .w-124 {
    width: 452px !important;
  }
  .res_remove_ml {
    width: 76% !important;
  }
  .rld-single-input {
    margin-left: 106px !important;
  }
  .rld-single-select {
    margin-left: 114px !important;
    margin-bottom: 10px;
  }
  .btn-yellow {
    width: 66% !important;
    margin-left: 89px !important;
  }
  div >>> .multiselect__tags {
    padding-right: 0px;
    margin-right: 0px;
    width: 295px !important;
    max-width: 295px !important;
    margin-bottom: 10px !important;
  }
}
@media (min-width: 500px) and (max-width: 529px) {
  .w-124 {
    width: 400px !important;
  }
  .res_remove_ml {
    width: 76% !important;
  }
  .rld-single-input {
    margin-left: 100px !important;
  }
  .rld-single-select {
    margin-left: 100px !important;
  }
  .btn-yellow {
    width: 66% !important;
    margin-left: 89px !important;
  }
  div >>> .multiselect__tags {
    padding-right: 0px;
    margin-right: 0px;
    width: 280px !important;
    max-width: 280px !important;
    margin-bottom: 10px !important;
  }
}
@media (min-width: 400px) and (max-width: 499px) {
  .w-124 {
    width: 400px !important;
  }
  .res_remove_ml {
    width: 76% !important;
  }
  .rld-single-input {
    margin-left: 70px !important;
  }
  .rld-single-select {
    margin-left: 70px !important;
  }
  .btn-yellow {
    width: 67% !important;
    margin-left: 60px !important;
  }
  div >>> .multiselect__tags {
    padding-right: 0px;
    margin-right: 0px;
    width: 230px !important;
    max-width: 230px !important;
    margin-bottom: 10px !important;
  }
}
@media (min-width: 300px) and (max-width: 400px) {
  .w-124 {
    width: 400px !important;
  }
  .res_remove_ml {
    width: 86% !important;
  }
  .rld-single-input {
    margin-left: 35px !important;
  }
  .rld-single-select {
    margin-left: 35px !important;
  }
  .btn-yellow {
    width: 76% !important;
    margin-left: 35px !important;
  }
  div >>> .multiselect__tags {
    padding-right: 0px;
    margin-right: 0px;
    width: 245px !important;
    max-width: 245px !important;
    margin-bottom: 10px !important;
  }
}
@media (min-width: 320px) and (max-width: 350px) {
  .w-124 {
    width: 400px !important;
  }
  .res_remove_ml {
    width: 80% !important;
  }
  .rld-single-input {
    margin-left: 35px !important;
  }
  .rld-single-select {
    margin-left: 35px !important;
  }
  .btn-yellow {
    width: 76% !important;
    margin-left: 30px !important;
  }
  div >>> .multiselect__tags {
    padding-right: 0px;
    margin-right: 0px;
    width: 200px !important;
    max-width: 200px !important;
    margin-bottom: 10px !important;
  }
}
</style>
